<template>
  <div>
    <ProcessLaunchWrapper
      description=""
      lastBpmnSrc="Kundenanschaltung_Widerruf.bpmn"
      title="Vertragswiderruf"
    >
      <WithdrawalStart/>
    </ProcessLaunchWrapper>
  </div>
</template>

<script>
import ProcessLaunchWrapper from '@/processLaunch/ProcessLaunchWrapper.vue';
import WithdrawalStart from '@/processLaunch/withdrawal/WithdrawalStart.vue';

export default {
  name: 'Withdrawal',
  components: {
    ProcessLaunchWrapper,
    WithdrawalStart
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

<template>
  <div>
    <ProcessLaunchWrapper
      lastBpmnSrc="Kundenanschaltung_Provisionierung.bpmn"
      title="Provisionierung"
      description=""
    >
      <ProvisioningProcessStart />
    </ProcessLaunchWrapper>
  </div>
</template>

<script>
import ProcessLaunchWrapper from '@/processLaunch/ProcessLaunchWrapper.vue';
import ProvisioningProcessStart from '@/processLaunch/provisioning/ProcessStart.vue';

export default {
  name: 'Provisioning',
  components: {
    ProcessLaunchWrapper,
    ProvisioningProcessStart
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

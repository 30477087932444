<template>
  <div>
    <ProcessLaunchWrapper
      description=""
      lastBpmnSrc="Kundenanschaltung_Provisionierung_TV.bpmn"
      title="TV Provisionierung"
    >
      <TvProvisioningStart/>
    </ProcessLaunchWrapper>
  </div>
</template>

<script>
import ProcessLaunchWrapper from '@/processLaunch/ProcessLaunchWrapper.vue';
import TvProvisioningStart from '@/processLaunch/provisioning/tv/ProcessDetails.vue';

export default {
  name: 'TvProvisioning',
  components: {
    ProcessLaunchWrapper,
    TvProvisioningStart
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('h1',{staticClass:"text-center",staticStyle:{"color":"#209bd8"}},[_vm._v(" FTTH-Verträge ")]),_c('br'),_c('div',[_c('v-row',{staticClass:"statistic-reporting-row"},[_c('v-col',{staticClass:"statistic-reporting-col",attrs:{"cols":"2"}},[_c('h4',{staticClass:"text-center pb-2"},[_vm._v(" Statistikauswertung ")]),_c('DatePicker',{attrs:{"label":"von","outlined":"","minDate":_vm.minStartDate,"maxDate":_vm.maxStartDate},model:{value:(_vm.datePickerStart),callback:function ($$v) {_vm.datePickerStart=$$v},expression:"datePickerStart"}}),_c('DatePicker',{attrs:{"label":"bis","outlined":"","minDate":_vm.minEndDate,"maxDate":_vm.maxEndDate},model:{value:(_vm.datePickerEnd),callback:function ($$v) {_vm.datePickerEnd=$$v},expression:"datePickerEnd"}}),_c('div',{staticClass:"statistic-metrics-export-btn"},[(
              _vm.userGroups.includes('camunda-admin') ||
                _vm.userGroups.includes('LgSpotControlling') ||
                _vm.userGroups.includes('LgSpotAuftragsmanagementLeitung') ||
                _vm.userGroups.includes('LgSpotAuftragsmanagement')
            )?_c('v-btn',{attrs:{"disabled":!_vm.mountedFinished || _vm.loadingStatistics,"color":"primary"},on:{"click":function($event){return _vm.exportMetrics()}}},[_vm._v(" Export ")]):_vm._e()],1),_c('br'),_c('br'),(_vm.statisticsStartDate === undefined)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Für das Datum "),_c('strong',[_vm._v(_vm._s(new Date(_vm.datePickerSelection.start).toLocaleDateString('de-DE')))]),_vm._v(" konnten keine Kennzahlen ermittelt werden. ")]):_vm._e(),(_vm.statisticsEndDate === undefined)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":"error"}},[_vm._v(" Für das Datum "),_c('strong',[_vm._v(_vm._s(new Date(_vm.datePickerSelection.end).toLocaleDateString('de-DE')))]),_vm._v(" konnten keine Kennzahlen ermittelt werden. ")]):_vm._e()],1),(_vm.mountedFinished)?_c('v-col',{staticClass:"statistic-reporting-col"},[_c('v-row',[_c('v-col',[_c('StatisticMetricsPieChartCard',{attrs:{"headline":"Auftragseingänge","endDateValue":_vm.statisticsEndDate !== undefined
                  ? _vm.statisticsEndDate.orderEntriesTotal
                  : null,"startDateValue":_vm.statisticsStartDate !== undefined
                  ? _vm.statisticsStartDate.orderEntriesTotal
                  : null,"options":_vm.optionsOrderEntry,"series":_vm.seriesOrderEntry,"pieHeight":"300","loading":_vm.loadingStatistics,"description":"Anzahl FTTH Auftragseingang.","descriptionDataSource":"Quelle: Vario DB; Bereich AM","height":"193"}}),_c('StatisticMetricsCard',{attrs:{"headline":"Homes Activated Technik","endDateValue":_vm.statisticsEndDate !== undefined
                  ? _vm.statisticsEndDate.contractsHomeActivatedTechn
                  : null,"startDateValue":_vm.statisticsStartDate !== undefined
                  ? _vm.statisticsStartDate.contractsHomeActivatedTechn
                  : null,"loading":_vm.loadingStatistics,"description":"Anzahl technisch aktiver FTTH Anschlüsse (MSAN bis ONT aktiv)","descriptionDataSource":"Quelle: Vario DB; Bereich Access","height":"193"}})],1),_c('v-col',[_c('StatisticMetricsNestedMetricsCard',{attrs:{"headline":"Verträge & Projekte in Bau","nestedTable":"","endDateValue":_vm.statisticsEndDate !== undefined
                  ? _vm.statisticsEndDate.contractsInConstructionStart
                  : null,"startDateValue":_vm.statisticsStartDate !== undefined
                  ? _vm.statisticsStartDate.contractsInConstructionStart
                  : null,"nestedStartDateValue":_vm.statisticsStartDate !== undefined
                  ? _vm.statisticsStartDate.projectsUnderConstruction
                  : null,"nestedEndDateValue":_vm.statisticsEndDate !== undefined
                  ? _vm.statisticsEndDate.projectsUnderConstruction
                  : null,"loading":_vm.loadingStatistics,"description":"Anzahl FTTH Verträge mit bestätigtem Status „Bau gestartet“                ","descriptionDataSource":"Quelle: Past DB; Bereich Bau","height":"193"}}),_c('StatisticMetricsCard',{attrs:{"headline":"Vertragsaktivierung","endDateValue":_vm.statisticsEndDate !== undefined
                  ? _vm.statisticsEndDate.contractsActive
                  : null,"startDateValue":_vm.statisticsStartDate !== undefined
                  ? _vm.statisticsStartDate.contractsActive
                  : null,"loading":_vm.loadingStatistics,"description":"Anzahl aktiver FTTH Verträge","descriptionDataSource":"Quelle: Vario DB; Bereich AM","height":"193"}})],1),_c('v-col',[_c('v-card',{staticClass:"statistic-card",attrs:{"width":"650","loading":_vm.loadingStatistics,"height":"193"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"5","indeterminate":""}})],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 text-center title-line-height"},[_vm._v(" Liegenschaftstatus "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"info-incomplete-statistic-metrics","color":_vm.incompletePropertyMetrics ? '#dc0000' : 'inherit'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,false,3359852136)},[_c('span',[_vm._v(" Hausanschluss gebaut: Anzahl FTTH Verträge mit bestätigtem Status „gebaut“"),_c('br'),_vm._v("Quelle: Past DB; Bereich Field Service ")]),_c('br'),_c('span',[_vm._v(" Hausanschluss gepatcht: Anzahl FTTH Verträge mit bestätigtem Status „gepatcht“"),_c('br'),_vm._v("Quelle: Past DB; Bereich Field Service ")]),_c('br'),_c('span',[_vm._v(" Pop Online: Anzahl FTTH Verträge mit bestätigtem Status „Pop Online“"),_c('br'),_vm._v("Quelle: Past DB; Bereich Access ")]),_c('br'),(_vm.incompletePropertyMetrics)?_c('ul',[_c('li',[_vm._v(" Die Daten für diese Kennzahlen sind unvollständig ")])]):_vm._e()])],1)],1)],1),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('h4',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" Hausanschluss gebaut ")]),_c('div',{staticClass:"v-card__text"},[_c('table',{staticClass:"property-statistic-metrics"},[_c('tr',[_c('td',[(
                                _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsStartDate !== undefined &&
                                  _vm.statisticsEndDate.contractsHouseConnectionBuilt !=
                                  null &&
                                  _vm.statisticsEndDate.contractsHouseConnectionBuilt !=
                                  undefined &&
                                  _vm.statisticsStartDate.contractsHouseConnectionBuilt !=
                                  null &&
                                  _vm.statisticsStartDate.contractsHouseConnectionBuilt !=
                                  undefined
                              )?_c('h1',{style:(_vm.getDifferenceColor(
                                  _vm.contractsHouseConnectionBuiltDifference
                                ))},[_vm._v(" "+_vm._s(_vm.contractsHouseConnectionBuiltDifference > 0 ? ("+" + _vm.contractsHouseConnectionBuiltDifference) : _vm.contractsHouseConnectionBuiltDifference)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])]),_c('tr',[_c('td',[(
                                _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsEndDate.contractsHouseConnectionBuilt !=
                                  null &&
                                  _vm.statisticsEndDate.contractsHouseConnectionBuilt !=
                                  undefined
                              )?_c('h1',[_vm._v(" "+_vm._s(_vm.statisticsEndDate.contractsHouseConnectionBuilt)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])])])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('h4',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" Hausanschluss gepatcht ")]),_c('div',{staticClass:"v-card__text"},[_c('table',{staticClass:"property-statistic-metrics"},[_c('tr',[_c('td',[(
                                _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsStartDate !== undefined &&
                                  _vm.statisticsEndDate.contractsHouseConnectionPatched !=
                                  null &&
                                  _vm.statisticsEndDate.contractsHouseConnectionPatched !=
                                  undefined &&
                                  _vm.statisticsStartDate.contractsHouseConnectionPatched !=
                                  null &&
                                  _vm.statisticsStartDate.contractsHouseConnectionPatched !=
                                  undefined
                              )?_c('h1',{style:(_vm.getDifferenceColor(
                                  _vm.contractsHouseConnectionPatchedDifference
                                ))},[_vm._v(" "+_vm._s(_vm.contractsHouseConnectionPatchedDifference > 0 ? ("+" + _vm.contractsHouseConnectionPatchedDifference) : _vm.contractsHouseConnectionPatchedDifference)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])]),_c('tr',[_c('td',[(
                                _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsEndDate.contractsHouseConnectionPatched !=
                                  null &&
                                  _vm.statisticsEndDate.contractsHouseConnectionPatched !=
                                  undefined
                              )?_c('h1',[_vm._v(" "+_vm._s(_vm.statisticsEndDate.contractsHouseConnectionPatched)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])])])])]),_c('v-col',{attrs:{"cols":"4"}},[_c('h4',{staticClass:"text-subtitle-2 text-center"},[_vm._v(" Pop Online ")]),_c('div',{staticClass:"v-card__text"},[_c('table',{staticClass:"property-statistic-metrics"},[_c('tr',[_c('td',[(
                                _vm.statisticsStartDate !== undefined &&
                                  _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsEndDate.contractsPopOnline !=
                                  null &&
                                  _vm.statisticsEndDate.contractsPopOnline !=
                                  undefined &&
                                  _vm.statisticsStartDate.contractsPopOnline !=
                                  null &&
                                  _vm.statisticsStartDate.contractsPopOnline !=
                                  undefined
                              )?_c('h1',{style:(_vm.getDifferenceColor(
                                  _vm.contractsPopOnlineDifference
                                ))},[_vm._v(" "+_vm._s(_vm.contractsPopOnlineDifference > 0 ? ("+" + _vm.contractsPopOnlineDifference) : _vm.contractsPopOnlineDifference)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])]),_c('tr',[_c('td',[(
                                _vm.statisticsEndDate !== undefined &&
                                  _vm.statisticsEndDate.contractsPopOnline !=
                                  null &&
                                  _vm.statisticsEndDate.contractsPopOnline !=
                                  undefined
                              )?_c('h1',[_vm._v(" "+_vm._s(_vm.statisticsEndDate.contractsPopOnline)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])])])])])],1)],1)],2),_c('div',{staticClass:"statistic-card-wrapper"},[_c('StatisticMetricsNestedMetricsCard',{attrs:{"headline":"Homes Activated Pay","nestedText":"","endDateValue":_vm.statisticsEndDate !== undefined
                    ? _vm.statisticsEndDate.contractsHomeActivatedPay
                    : null,"startDateValue":_vm.statisticsStartDate !== undefined
                    ? _vm.statisticsStartDate.contractsHomeActivatedPay
                    : null,"loading":_vm.loadingStatistics,"description":"Anzahl berechneter FTTH Verträge im letzten Rechnungslauf","descriptionDataSource":"Quelle: Vario DB; Bereich RW","nestedTextHeadline":_vm.homesActivatedPayNestedTextContent,"height":"193"}}),_c('StatisticMetricsPieChartCard',{attrs:{"headline":"Vertragsdeaktivierung","startDateValue":_vm.statisticsStartDate !== undefined
                    ? _vm.statisticsStartDate.contractsDeactivated
                    : null,"endDateValue":_vm.statisticsEndDate !== undefined
                    ? _vm.statisticsEndDate.contractsDeactivated
                    : null,"options":_vm.optionsContractsDeactivated,"series":_vm.seriesContractsDeactivated,"pieHeight":"399","loading":_vm.loadingStatistics,"description":"Anzahl deaktivierter FTTH Verträge Widerruf, Kündigung, Sonderkündigung, etc.","descriptionDataSource":"Quelle: Vario DB; Bereich AM","invertDifferenceColor":"","height":"193"}})],1)],1)],1)],1):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <SupportStartWrapper />
  </div>
</template>

<script>
import SupportStartWrapper from '@/processLaunch/support/SupportStartWrapper.vue';

export default {
  name: 'SupportStart',
  components: {
    SupportStartWrapper
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

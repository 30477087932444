<template>
  <div><ContractSavedForSecondConstructionPhaseDashboard /></div>
</template>

<script>
import ContractSavedForSecondConstructionPhaseDashboard from '@/dashboard/ContractSavedForSecondConstructionPhaseDashboard.vue';
export default {
  name: 'Dashboard',
  components: { ContractSavedForSecondConstructionPhaseDashboard },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

import { render, staticRenderFns } from "./RoutesPermissionOverview.vue?vue&type=template&id=0db9b399&scoped=true&"
import script from "./RoutesPermissionOverview.vue?vue&type=script&lang=js&"
export * from "./RoutesPermissionOverview.vue?vue&type=script&lang=js&"
import style0 from "./RoutesPermissionOverview.vue?vue&type=style&index=0&id=0db9b399&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db9b399",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VCard,VContainer,VIcon,VList,VListGroup,VListItem,VListItemIcon,VListItemTitle})

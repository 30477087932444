<template>
  <v-container>
    <h1 class="text-center" style="color: #209bd8">
      FTTH-Verträge
    </h1>
    <br />
    <div>
      <v-row class="statistic-reporting-row">
        <v-col cols="2" class="statistic-reporting-col">
          <h4 class="text-center pb-2">
            Statistikauswertung
          </h4>
          <DatePicker
            v-model="datePickerStart"
            label="von"
            outlined
            :minDate="minStartDate"
            :maxDate="maxStartDate"
          />
          <DatePicker
            v-model="datePickerEnd"
            label="bis"
            outlined
            :minDate="minEndDate"
            :maxDate="maxEndDate"
          />
          <div class="statistic-metrics-export-btn">
            <v-btn
              v-if="
                userGroups.includes('camunda-admin') ||
                  userGroups.includes('LgSpotControlling') ||
                  userGroups.includes('LgSpotAuftragsmanagementLeitung') ||
                  userGroups.includes('LgSpotAuftragsmanagement')
              "
              :disabled="!mountedFinished || loadingStatistics"
              color="primary"
              @click="exportMetrics()"
            >
              Export
            </v-btn>
          </div>
          <br />
          <br />
          <v-alert
            v-if="statisticsStartDate === undefined"
            dense
            outlined
            type="error"
          >
            Für das Datum
            <strong>{{
              new Date(datePickerSelection.start).toLocaleDateString('de-DE')
            }}</strong>
            konnten keine Kennzahlen ermittelt werden.
          </v-alert>
          <v-alert
            v-if="statisticsEndDate === undefined"
            dense
            outlined
            type="error"
          >
            Für das Datum
            <strong>{{
              new Date(datePickerSelection.end).toLocaleDateString('de-DE')
            }}</strong>
            konnten keine Kennzahlen ermittelt werden.
          </v-alert>
        </v-col>
        <v-col v-if="mountedFinished" class="statistic-reporting-col">
          <v-row>
            <v-col>
              <StatisticMetricsPieChartCard
                headline="Auftragseingänge"
                :endDateValue="
                  statisticsEndDate !== undefined
                    ? statisticsEndDate.orderEntriesTotal
                    : null
                "
                :startDateValue="
                  statisticsStartDate !== undefined
                    ? statisticsStartDate.orderEntriesTotal
                    : null
                "
                :options="optionsOrderEntry"
                :series="seriesOrderEntry"
                pieHeight="300"
                :loading="loadingStatistics"
                description="Anzahl FTTH Auftragseingang."
                descriptionDataSource="Quelle: Vario DB; Bereich AM"
                height="193"
              />
              <StatisticMetricsCard
                headline="Homes Activated Technik"
                :endDateValue="
                  statisticsEndDate !== undefined
                    ? statisticsEndDate.contractsHomeActivatedTechn
                    : null
                "
                :startDateValue="
                  statisticsStartDate !== undefined
                    ? statisticsStartDate.contractsHomeActivatedTechn
                    : null
                "
                :loading="loadingStatistics"
                description="Anzahl technisch aktiver FTTH Anschlüsse (MSAN bis ONT aktiv)"
                descriptionDataSource="Quelle: Vario DB; Bereich Access"
                height="193"
              />
            </v-col>
            <v-col>
              <StatisticMetricsNestedMetricsCard
                headline="Verträge &amp; Projekte in Bau"
                nestedTable
                :endDateValue="
                  statisticsEndDate !== undefined
                    ? statisticsEndDate.contractsInConstructionStart
                    : null
                "
                :startDateValue="
                  statisticsStartDate !== undefined
                    ? statisticsStartDate.contractsInConstructionStart
                    : null
                "
                :nestedStartDateValue="
                  statisticsStartDate !== undefined
                    ? statisticsStartDate.projectsUnderConstruction
                    : null
                "
                :nestedEndDateValue="
                  statisticsEndDate !== undefined
                    ? statisticsEndDate.projectsUnderConstruction
                    : null
                "
                :loading="loadingStatistics"
                description="Anzahl FTTH Verträge mit bestätigtem Status „Bau gestartet“                "
                descriptionDataSource="Quelle: Past DB; Bereich Bau"
                height="193"
              />
              <StatisticMetricsCard
                headline="Vertragsaktivierung"
                :endDateValue="
                  statisticsEndDate !== undefined
                    ? statisticsEndDate.contractsActive
                    : null
                "
                :startDateValue="
                  statisticsStartDate !== undefined
                    ? statisticsStartDate.contractsActive
                    : null
                "
                :loading="loadingStatistics"
                description="Anzahl aktiver FTTH Verträge"
                descriptionDataSource="Quelle: Vario DB; Bereich AM"
                height="193"
              />
            </v-col>
            <v-col>
              <v-card
                width="650"
                class="statistic-card"
                :loading="loadingStatistics"
                height="193"
              >
                <template slot="progress">
                  <v-progress-linear
                    color="primary"
                    height="5"
                    indeterminate
                  ></v-progress-linear>
                </template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-h6 text-center title-line-height"
                    >
                      Liegenschaftstatus
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            id="info-incomplete-statistic-metrics"
                            v-bind="attrs"
                            :color="
                              incompletePropertyMetrics ? '#dc0000' : 'inherit'
                            "
                            v-on="on"
                          >
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          Hausanschluss gebaut: Anzahl FTTH Verträge mit
                          bestätigtem Status „gebaut“<br />Quelle: Past DB;
                          Bereich Field Service
                        </span>
                        <br />
                        <span>
                          Hausanschluss gepatcht: Anzahl FTTH Verträge mit
                          bestätigtem Status „gepatcht“<br />Quelle: Past DB;
                          Bereich Field Service
                        </span>
                        <br />
                        <span>
                          Pop Online: Anzahl FTTH Verträge mit bestätigtem
                          Status „Pop Online“<br />Quelle: Past DB; Bereich
                          Access
                        </span>
                        <br />
                        <ul v-if="incompletePropertyMetrics">
                          <li>
                            Die Daten für diese Kennzahlen sind unvollständig
                          </li>
                        </ul>
                      </v-tooltip>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-container>
                  <v-row>
                    <v-col cols="4">
                      <h4 class="text-subtitle-2 text-center">
                        Hausanschluss gebaut
                      </h4>
                      <div class="v-card__text">
                        <table class="property-statistic-metrics">
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsEndDate !== undefined &&
                                    statisticsStartDate !== undefined &&
                                    statisticsEndDate.contractsHouseConnectionBuilt !=
                                    null &&
                                    statisticsEndDate.contractsHouseConnectionBuilt !=
                                    undefined &&
                                    statisticsStartDate.contractsHouseConnectionBuilt !=
                                    null &&
                                    statisticsStartDate.contractsHouseConnectionBuilt !=
                                    undefined
                                "
                                :style="
                                  getDifferenceColor(
                                    contractsHouseConnectionBuiltDifference
                                  )
                                "
                              >
                                {{
                                  contractsHouseConnectionBuiltDifference > 0
                                    ? `+${contractsHouseConnectionBuiltDifference}`
                                    : contractsHouseConnectionBuiltDifference
                                }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsEndDate !== undefined &&
                                    statisticsEndDate.contractsHouseConnectionBuilt !=
                                    null &&
                                    statisticsEndDate.contractsHouseConnectionBuilt !=
                                    undefined
                                "
                              >
                                {{
                                  statisticsEndDate.contractsHouseConnectionBuilt
                                }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <h4 class="text-subtitle-2 text-center">
                        Hausanschluss gepatcht
                      </h4>
                      <div class="v-card__text">
                        <table class="property-statistic-metrics">
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsEndDate !== undefined &&
                                    statisticsStartDate !== undefined &&
                                    statisticsEndDate.contractsHouseConnectionPatched !=
                                    null &&
                                    statisticsEndDate.contractsHouseConnectionPatched !=
                                    undefined &&
                                    statisticsStartDate.contractsHouseConnectionPatched !=
                                    null &&
                                    statisticsStartDate.contractsHouseConnectionPatched !=
                                    undefined
                                "
                                :style="
                                  getDifferenceColor(
                                    contractsHouseConnectionPatchedDifference
                                  )
                                "
                              >
                                {{
                                  contractsHouseConnectionPatchedDifference > 0
                                    ? `+${contractsHouseConnectionPatchedDifference}`
                                    : contractsHouseConnectionPatchedDifference
                                }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsEndDate !== undefined &&
                                    statisticsEndDate.contractsHouseConnectionPatched !=
                                    null &&
                                    statisticsEndDate.contractsHouseConnectionPatched !=
                                    undefined
                                "
                              >
                                {{
                                  statisticsEndDate.contractsHouseConnectionPatched
                                }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                    <v-col cols="4">
                      <h4 class="text-subtitle-2 text-center">
                        Pop Online
                      </h4>
                      <div class="v-card__text">
                        <table class="property-statistic-metrics">
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsStartDate !== undefined &&
                                    statisticsEndDate !== undefined &&
                                    statisticsEndDate.contractsPopOnline !=
                                    null &&
                                    statisticsEndDate.contractsPopOnline !=
                                    undefined &&
                                    statisticsStartDate.contractsPopOnline !=
                                    null &&
                                    statisticsStartDate.contractsPopOnline !=
                                    undefined
                                "
                                :style="
                                  getDifferenceColor(
                                    contractsPopOnlineDifference
                                  )
                                "
                              >
                                {{
                                  contractsPopOnlineDifference > 0
                                    ? `+${contractsPopOnlineDifference}`
                                    : contractsPopOnlineDifference
                                }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h1
                                v-if="
                                  statisticsEndDate !== undefined &&
                                    statisticsEndDate.contractsPopOnline !=
                                    null &&
                                    statisticsEndDate.contractsPopOnline !=
                                    undefined
                                "
                              >
                                {{ statisticsEndDate.contractsPopOnline }}
                              </h1>
                              <h1 v-else>
                                -
                              </h1>
                            </td>
                            <td>
                              <h4>Verträge</h4>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <div class="statistic-card-wrapper">
                <StatisticMetricsNestedMetricsCard
                  headline="Homes Activated Pay"
                  nestedText
                  :endDateValue="
                    statisticsEndDate !== undefined
                      ? statisticsEndDate.contractsHomeActivatedPay
                      : null
                  "
                  :startDateValue="
                    statisticsStartDate !== undefined
                      ? statisticsStartDate.contractsHomeActivatedPay
                      : null
                  "
                  :loading="loadingStatistics"
                  description="Anzahl berechneter FTTH Verträge im letzten Rechnungslauf"
                  descriptionDataSource="Quelle: Vario DB; Bereich RW"
                  :nestedTextHeadline="homesActivatedPayNestedTextContent"
                  height="193"
                />
                <StatisticMetricsPieChartCard
                  headline="Vertragsdeaktivierung"
                  :startDateValue="
                    statisticsStartDate !== undefined
                      ? statisticsStartDate.contractsDeactivated
                      : null
                  "
                  :endDateValue="
                    statisticsEndDate !== undefined
                      ? statisticsEndDate.contractsDeactivated
                      : null
                  "
                  :options="optionsContractsDeactivated"
                  :series="seriesContractsDeactivated"
                  pieHeight="399"
                  :loading="loadingStatistics"
                  description="Anzahl deaktivierter FTTH Verträge Widerruf, Kündigung, Sonderkündigung, etc."
                  descriptionDataSource="Quelle: Vario DB; Bereich AM"
                  invertDifferenceColor
                  height="193"
                />
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import VueApexCharts from 'vue-apexcharts';
import { LocalDate } from '@js-joda/core';
import DatePicker from '@/components/elements/DatePicker.vue';
import StatisticMetricsCard from '@/components/elements/StatisticMetricsCard.vue';
import StatisticMetricsNestedMetricsCard from '@/components/elements/StatisticMetricsNestedMetricsCard.vue';

import StatisticMetricsPieChartCard from '@/components/elements/StatisticMetricsPieChartCard.vue';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
export default {
  name: 'StatistikDashboard',
  components: {
    VueApexCharts,
    ErrorMessageBuilder,
    StatisticMetricsPieChartCard,
    StatisticMetricsNestedMetricsCard,
    StatisticMetricsCard,
    DatePicker
  },
  data: () => ({
    mountedFinished: false,
    loadingStatistics: true,
    statisticsStartDate: {},
    seriesOrderEntry: [],
    seriesContractsDeactivated: [],
    statisticsEndDate: {},
    minStartDate: '',
    maxStartDate: '',
    minEndDate: '',
    maxEndDate: '',
    datePickerStart: '',
    show: false,
    datePickerEnd: '',
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    optionsOrderEntry: {
      labels: [
        'Auftragseingänge Legacy',
        'Auftragseingänge Website',
        'Auftragseingänge D2D Vertrieb'
      ],
      legend: {
        formatter: function (label, opts) {
          return `${label} (${opts.w.globals.series[opts.seriesIndex]})`;
        },
        horizontalAlign: 'left',
        position: 'bottom',
        markers: {
          width: 10,
          height: 10
        },
        fontSize: '12px'
      },
      colors: ['#209bd8', '#50AF32', '#FFB400']
    },
    optionsContractsDeactivated: {
      labels: [
        'Widerruf',
        'Kündigung in Mindestvertragslaufzeit',
        'Kündigung nach Mindestvertragslaufzeit',
        'Sonderkündigung (Sterbefall, Umzug, Hausverkauf, Vertragsinhaberwechsel)',
        'Keine Grundangabe'
      ],
      legend: {
        formatter: function (label, opts) {
          return `${label} (${opts.w.globals.series[opts.seriesIndex]})`;
        },
        horizontalAlign: 'left',
        position: 'bottom',
        markers: {
          width: 10,
          height: 10
        },
        fontSize: '12px'
      },
      colors: ['#EC6727', '#50AF32', '#209bd8', '#FFB400', '#204165']
    }
  }),
  computed: {
    userGroups() {
      return this.$auth.user().userRoles;
    },
    homesActivatedPayNestedTextContent: function () {
      const billingHistoryStart =
        this.months[
          LocalDate.parse(this.datePickerSelection.start)
            .minusDays(6)
            .monthValue() - 1
        ];
      const billingHistoryEnd =
        this.months[
          LocalDate.parse(this.datePickerSelection.end)
            .minusDays(6)
            .monthValue() - 1
        ];

      return `Anzahl Verträge im Rechnungslauf ${billingHistoryEnd} im Vergleich zum Rechnungslauf ${billingHistoryStart}`;
    },
    incompletePropertyMetrics: function () {
      return (
        this.statisticsEndDate === undefined ||
        this.statisticsStartDate === undefined ||
        this.statisticsEndDate.contractsPopOnline == null ||
        this.statisticsEndDate.contractsPopOnline === undefined ||
        this.statisticsStartDate.contractsPopOnline == null ||
        this.statisticsStartDate.contractsPopOnline === undefined ||
        this.statisticsEndDate.contractsHouseConnectionBuilt == null ||
        this.statisticsEndDate.contractsHouseConnectionBuilt === undefined ||
        this.statisticsStartDate.contractsHouseConnectionBuilt == null ||
        this.statisticsStartDate.contractsHouseConnectionBuilt === undefined ||
        this.statisticsEndDate.contractsHouseConnectionPatched == null ||
        this.statisticsEndDate.contractsHouseConnectionPatched === undefined ||
        this.statisticsStartDate.contractsHouseConnectionPatched == null ||
        this.statisticsStartDate.contractsHouseConnectionPatched === undefined
      );
    },
    contractsPopOnlineDifference: function () {
      return (
        this.statisticsEndDate.contractsPopOnline -
        this.statisticsStartDate.contractsPopOnline
      );
    },
    contractsHouseConnectionBuiltDifference: function () {
      return (
        this.statisticsEndDate.contractsHouseConnectionBuilt -
        this.statisticsStartDate.contractsHouseConnectionBuilt
      );
    },
    contractsHouseConnectionPatchedDifference: function () {
      return (
        this.statisticsEndDate.contractsHouseConnectionPatched -
        this.statisticsStartDate.contractsHouseConnectionPatched
      );
    },
    datePickerSelection: function () {
      if (
        this.datePickerStart &&
        this.datePickerEnd &&
        this.datePickerStart < this.datePickerEnd
      ) {
        const datePickerSelection = {};
        datePickerSelection.start = this.datePickerStart;
        datePickerSelection.end = this.datePickerEnd;
        return datePickerSelection;
      }
      return undefined;
    }
  },
  watch: {
    datePickerStart(newValue) {
      if (newValue) {
        this.minEndDate = LocalDate.parse(newValue).plusDays(1).toString();
      }
    },
    datePickerEnd(newValue) {
      if (newValue) {
        this.maxStartDate = LocalDate.parse(newValue).minusDays(1).toString();
      }
    },
    datePickerSelection(newValue) {
      const backendCalls = [];
      if (newValue) {
        this.loadingStatistics = true;
        backendCalls.push(this.retrieveStatisticsStartDate());
        backendCalls.push(this.retrieveStatisticsEndDate());
        Promise.allSettled(backendCalls).then(() => {
          this.createSeries();
          this.loadingStatistics = false;
          if (!this.mountedFinished) {
            this.mountedFinished = true;
          }
        });
      }
    }
  },
  mounted() {
    this.minStartDate = '2022-01-01';
    // defaultEndDate is yesterday
    const defaultEndDate = LocalDate.now().minusDays(1);
    // at first defaultStartDate is first day of current month
    var defaultStartDate = defaultEndDate.withDayOfMonth(1);
    // if defaultEndDate is the first day of a month the defaultStartDate has to be the last day of the previous month
    if (defaultEndDate.equals(defaultStartDate)) {
      defaultStartDate = defaultStartDate.minusDays(1);
    }
    this.datePickerStart = defaultStartDate.toString();
    this.datePickerEnd = defaultEndDate.toString();
    this.maxStartDate = defaultEndDate.toString();
    this.maxEndDate = defaultEndDate.toString();
  },

  methods: {
    getDifferenceColor(value) {
      if (value > 0) {
        return 'color: green';
      } else if (value < 0) {
        return 'color: #dc0000';
      } else {
        return 'color: #666666';
      }
    },
    createSeries() {
      this.seriesOrderEntry = [];
      this.seriesContractsDeactivated = [];
      if (
        this.datePickerStart &&
        this.datePickerEnd &&
        this.datePickerStart < this.datePickerEnd &&
        this.statisticsEndDate
      ) {
        // Calculate the seriesOrderEntry of pie chart in case of date picker selection
        this.seriesOrderEntry.push(this.statisticsEndDate.orderEntriesLegacy);
        this.seriesOrderEntry.push(this.statisticsEndDate.orderEntriesB2C);
        this.seriesOrderEntry.push(this.statisticsEndDate.orderEntriesB2B);

        // Calculate the seriesContractsDeactivated of pie chart in case of date picker selection
        this.seriesContractsDeactivated.push(
          this.statisticsEndDate.contractsRevoked
        );
        this.seriesContractsDeactivated.push(
          this.statisticsEndDate.contractsTerminatedInMinPeriod
        );
        this.seriesContractsDeactivated.push(
          this.statisticsEndDate.contractsTerminatedAfterMinPeriod
        );
        this.seriesContractsDeactivated.push(
          this.statisticsEndDate.contractsTerminatedSpecialReason
        );
        this.seriesContractsDeactivated.push(
          this.statisticsEndDate.contractsTerminatedWithoutReason
        );
      } else {
        this.seriesEndOrderEntry = [];
        this.seriesContractsDeactivated = [];
      }
    },
    retrieveStatisticsStartDate() {
      // In case of date selection, the statistics are retrieved accordingly.
      if (this.datePickerSelection) {
        return HTTP.get(
          `/past/geo/filtered-management-kpis?exactDate=${this.datePickerSelection.start}`
        )
          .then((response) => {
            this.statisticsStartDate = response.data[0];
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                `Die FTTH Kennzahlen ab ${this.datePickerSelection.start} konnten nicht abgerufen werden`
              )
            )
          )
          .finally();
      }
    },
    retrieveStatisticsEndDate() {
      // In case of date selection, the statistics are retrieved accordingly.
      if (this.datePickerSelection) {
        return HTTP.get(
          `/past/geo/filtered-management-kpis?exactDate=${this.datePickerSelection.end}`
        )
          .then((response) => {
            this.statisticsEndDate = response.data[0];
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                `Die FTTH Kennzahlen bis ${this.datePickerSelection.end} konnten nicht abgerufen werden`
              )
            )
          )
          .finally();
      }
    },
    exportMetrics() {
      HTTP.get(
        `/statistics/exportManagementKpis?startDate=${this.datePickerSelection.start}&endDate=${this.datePickerSelection.end}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.statistic-reporting-row .statistic-reporting-col:first-child {
  padding-right: 3px;
}
.statistic-reporting-row .statistic-reporting-col:last-child {
  padding-left: 3px;
}
.statistic-card-wrapper .statistic-card:first-child {
  float: left;
}
.statistic-card-wrapper .statistic-card:last-child {
  float: right;
}
.statistic-card {
  margin-bottom: 10px;
  display: inline-block;
}
.property-statistic-metrics {
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
}
.property-statistic-metrics td {
  vertical-align: 1px;
  padding-left: 5px;
}
::v-deep #info-incomplete-statistic-metrics {
  position: absolute;
  top: 7px;
  right: 7px;
}
.statistic-metrics-export-btn {
  float: right;
  padding-right: 4px;
}

.title-line-height {
  line-height: 1.2 !important;
}
</style>

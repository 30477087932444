var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-slide-group',{staticClass:"task-filter-bar",attrs:{"show-arrows":true,"multiple":""},model:{value:(_vm.selectedTaskTypeFilters),callback:function ($$v) {_vm.selectedTaskTypeFilters=$$v},expression:"selectedTaskTypeFilters"}},[_c('v-slide-item',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"remove-filter-btn"},'div',attrs,false),on),[_c('v-btn',_vm._g({attrs:{"disabled":_vm.selectedTaskTypeFilters.length <= 0,"color":"primary","depressed":"","rounded":"","small":""},on:{"click":function($event){_vm.selectedTaskTypeFilters = []}}},on),[_c('v-icon',[_vm._v(" mdi-filter-off")])],1)],1)]}}])},[_c('span',[_vm._v("Alle Filter entfernen")])])],1),_vm._l((_vm.allTaskTypes),function(taskType){return _c('v-slide-item',{key:taskType.taskName,attrs:{"value":taskType.taskName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"task-filter",attrs:{"color":active ? 'primary' : '',"disabled":_vm.isLoading,"input-value":active,"depressed":"","rounded":"","small":""},on:{"click":toggle}},[_vm._v(" "+_vm._s(taskType.taskName)+" ("+_vm._s(taskType.count)+") ")])]}}],null,true)})})],2),_c('v-row',[_c('v-col',{staticClass:"tasklist",class:{ 'light-shadow': _vm.contentId === 'taskDetails' },attrs:{"md":_vm.contentId === 'taskDetails' || _vm.contentId === 'createGenericTask'
          ? 3
          : 12}},[_c('TaskList',{attrs:{"count":_vm.itemTotalCount,"isLoading":_vm.isLoading,"items":_vm.items,"selectedId":_vm.selectedItem !== null ? _vm.selectedItem.id : null,"selectedTaskTypeFilters":_vm.selectedTaskTypeFilters},on:{"onCreateTask":function($event){_vm.contentId = 'createGenericTask'},"onPaginationPageChanged":_vm.onPaginationPageChanged,"onRefresh":_vm.getTasks,"onSearchTextChanged":function($event){return _vm.onSearchTextChanged($event)},"onSelect":_vm.onSelectTask,"onSelectedTaskTypeFiltersChange":_vm.onSelectedTaskTypeFiltersChange,"onSortTypeChanged":function($event){return _vm.onSortTypeChanged($event)}}})],1),(_vm.contentId === 'taskDetails' && _vm.contentId != 'createGenericTask')?_c('v-col',[_c('TaskContent',{attrs:{"data":_vm.selectedItem},on:{"onClose":function($event){return _vm.selectEntry(null)},"onUpdateEntry":_vm.updateSingleEntry,"refresh":_vm.refreshTasks}})],1):(_vm.contentId === 'createGenericTask')?_c('v-col',[_c('CreateTask',{on:{"onClose":function($event){_vm.contentId = null}}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
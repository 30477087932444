<template>
  <div>
    <ProcessLaunchWrapper
      description=""
      lastBpmnSrc="Kundenanschaltung_Portierung.bpmn"
      title="Telefonnummerportierung"
    >
      <PhoneNumberPortingStart/>
    </ProcessLaunchWrapper>
  </div>
</template>

<script>
import ProcessLaunchWrapper from '@/processLaunch/ProcessLaunchWrapper.vue';
import PhoneNumberPortingStart from '@/processLaunch/porting/PhoneNumberPorting.vue';

export default {
  name: 'PhoneNumberPorting',
  components: {
    ProcessLaunchWrapper,
    PhoneNumberPortingStart
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

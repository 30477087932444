<template>
  <DisplayContainer label="RADIUS Session Lock">
    <v-tabs
      v-model="tab"
      background-color="transparent"
    >
      <v-tab>
        Gesperrte Sessions
      </v-tab>
      <v-tab>
        Session suche
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <div class="sessions-wrapper">
              <RadiusSessionLockList ref="sessionLock" />
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <div class="search-wrapper">
              <RadiusSessionSearch ref="sessionSearch" />
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </DisplayContainer>
</template>

<script>
import DisplayContainer from '@/components/elements/DisplayContainer.vue';
import RadiusSessionSearch from '@/components/RadiusSessionSearch.vue';
import RadiusSessionLockList from '@/components/RadiusSessionLockList.vue';

export default {
  name: 'RadiusSessionLock',
  components: {
    DisplayContainer,
    RadiusSessionSearch,
    RadiusSessionLockList
  },
  data: () => ({
    tab: null
  }),
  watch: {
    tab() {
      if (this.tab === 0 && this.$refs.sessionLock) {
        this.$refs.sessionLock.lookupSessions();
        history.pushState(
          '',
          document.title,
          window.location.pathname + window.location.search
        );
      }
      if (this.tab === 1 && this.$refs.sessionSearch) {
        this.$refs.sessionSearch.lookupSessions();
      }
    }
  },
  created: function () {
    const hash = document.location.hash.substring(1);
    const hashParts = hash.split(';');
    if (hash.length === 0 || hashParts.length < 2) return;
    if (hashParts[0] === 'lookup') {
      this.tab = 1;
    }
  },
  methods: {}
};
</script>

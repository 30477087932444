var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"margin-bottom",attrs:{"elevation":"1"}},[_c('v-card-title',[_vm._v(" Benutzer mit eingerichteter Zwei-Faktor-Authentifikation "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","loading":_vm.loading},model:{value:(_vm.userFilter),callback:function ($$v) {_vm.userFilter=$$v},expression:"userFilter"}})],1),_c('v-data-table',{staticClass:"elevation-1 margin-top-1",attrs:{"headers":[
        {
          text: 'Benutzer',
          value: 'userName',
          width: '80%'
        },
        {
          text: 'Aktionen',
          value: 'actions'
        }
      ],"items":_vm.tableData,"search":_vm.userFilter,"dense":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"button-wrapper"},[_c('v-btn',{attrs:{"small":"","outlined":"","color":"var(--goe-fontColor-darkish)"},on:{"click":function($event){return _vm.resetUser2FA(item.userName)}}},[_vm._v(" 2FA Zurücksetzen ")])],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
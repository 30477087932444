<template>
  <div>
    <v-card>
      <v-card-title> Statistik </v-card-title>
      <v-tabs background-color="rgba(0,0,0,0.05)">
        <v-tab
          disabled
          @change="
            () => {
              rawResultProject = {};
              rawResultProjectSimpel = {};
              cityDistrictTabActive = true;
              projectTabActive = false;
              projectSimpleTabActive = false;
            }
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-obsolet"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <ul>
              <li>Ort / Ortsteile (obsolet)</li>
              <ul>
                <li>Anzeige eines oder mehrer Ortsteile im Ort</li>
                <li>
                  Ist abhängig von der Schreibweise der Adresse im VarioCRM
                  System
                </li>
                <li>
                  Adressen die in der Adressdatenbank Unbekannte sind werden
                  angezeigt
                </li>
              </ul>
            </ul>
          </v-tooltip>
          <v-icon left>
            mdi-home-city-outline
          </v-icon>
          Ort / Ortsteil
        </v-tab>
        <v-tab
          disabled
          @change="
            () => {
              rawResult = {};
              rawResultProjectSimpel = {};
              cityDistrictTabActive = false;
              projectTabActive = true;
              projectSimpleTabActive = false;
            }
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-obsolet"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <ul>
              <li>Projekt (obsolet)</li>
              <ul>
                <li>
                  Hier werden alle Verträge ausgewertet die zu der Liste der
                  Projektortsteile gehören
                </li>
                <li>
                  Ist abhängig von der Schreibweise der Adresse im VarioCRM
                  System
                </li>
                <li>
                  Adressen die in der Adressdatenbank Unbekannte sind werden
                  angezeigt
                </li>
              </ul>
            </ul>
          </v-tooltip>
          <v-icon left>
            mdi-vector-polygon
          </v-icon>
          Projekt
        </v-tab>
        <v-tab
          @change="
            () => {
              rawResult = {};
              rawResultProject = {};
              cityDistrictTabActive = false;
              projectTabActive = false;
              projectSimpleTabActive = true;
            }
          "
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-obsolet"
                small
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <ul>
              <li>Projekt (Vertragszuordnung)</li>
              <ul>
                <li>
                  Hier werden alle Verträge ausgewertet die bisher mit einem
                  Projekt in Verbindung genommen wurden
                </li>
                <li>
                  Wenn Aufträge nicht in dieser Liste Auftreten müssen sie noch
                  nachgebessert werden.
                </li>
                <li>Dient zur Grundlage der in Monday reporteten Zahlen</li>
                <li>
                  Ist abhängig von Adressdatenbank in PAST und den
                  Projektpolygonen
                </li>
              </ul>
            </ul>
          </v-tooltip>
          <v-icon left>
            mdi-vector-polygon
          </v-icon>
          Projekt (Vertragszuordnung)
        </v-tab>
        <v-tab-item>
          <v-card-text>
            <AddressDistrictSelection
              :city="city"
              :district="district"
              @updateCity="updateCity"
              @updateDistrict="updateDistrict"
              @updateDistrictObject="updateDistrictObject"
            />
            <br />
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <ProjectNameSelection
              :projectItem="projectItem"
              @updateProjektItem="updateProjektItem"
            />
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <ProjectNameSelection
              :projectItem="projectSimpleItem"
              @updateProjektItem="updateProjektItem"
            />
          </v-card-text>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-btn color="primary" @click="retrieveStatisticData">
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          Ausbauquote berechnen
        </v-btn>
        <v-btn
          v-if="cityDistrictTabActive || projectTabActive"
          class="export-btn"
          color="primary"
          :disabled="
            (!mergedAddresses || mergedAddresses.length === 0) &&
              (!notFoundAddresses || notFoundAddresses.length === 0)
          "
          @click="exportExcel()"
        >
          Export
        </v-btn>
        <v-btn
          v-if="projectSimpleTabActive"
          class="export-btn"
          color="primary"
          :disabled="
            (!addressesProjectSimple || addressesProjectSimple.length === 0) &&
              (!notFoundAddressesSimpel || notFoundAddressesSimpel.length === 0)
          "
          @click="exportExcel()"
        >
          Export
        </v-btn>
      </v-card-actions>
    </v-card>

    <br />
    <!--City district statistic overview-->
    <template
      v-if="
        cityDistrictTabActive &&
          rawResult &&
          rawResult.districts &&
          rawResult.districts.length &&
          city
      "
    >
      <DistrictCard
        :key="-1"
        :city="rawResult.city"
        :rawResult="rawResult"
      />
      <br />&nbsp;
      <v-row>
        <v-col md="9">
          <h4 style="color: #888; margin: 16px 0">
            Ortsteile:
          </h4>
        </v-col>
        <v-col md="3" class="text-right">
          <v-switch
            v-model="lineView"
            label="Alternative Darstellung"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col md="12">
          <template>
            <template v-for="(item, index) in rawResult.districts">
              <DistrictCard
                :key="index"
                :rawResult="item"
                :list-view="true"
                :line-view="lineView"
                city=""
                @showDistrict="showDistrict"
              />
            </template>
          </template>
        </v-col>
      </v-row>
      <div v-if="notFoundAddresses">
        <v-alert
          v-if="rawResult && rawResult.districts && rawResult.districts.length"
          dense
          type="error"
        >
          Einige Adressen konnten in der Address-Datenbank nicht gefunden
          werden!
        </v-alert>
        <v-data-table
          style="background: rgba(255, 0, 0, 0.05)"
          :headers="tableHeadersNotFound"
          :items="notFoundAddresses"
          :item-class="row_classes"
          multi-sort
          class="elevation-1 my-6"
        >
          <template v-slot:[`item.id`]>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="red"
                  v-on="on"
                >
                  mdi-home
                </v-icon>
              </template>
              <span>Annahme Hauseinheit</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.houseno`]="{ item }">
            {{ item.houseno }}{{ item.houseno_suppl }}
          </template>
          <template v-slot:[`item.homes`]="{ item }">
            <v-icon>mdi-home</v-icon> {{ item.homes }}
          </template>
          <template v-slot:[`item.offices`]="{ item }">
            <v-icon>mdi-factory</v-icon> {{ item.offices }}
          </template>
          <template v-slot:[`item.contracts`]="{ item }">
            <v-icon v-if="item.isftth === true" color="green">
              mdi-file-check
            </v-icon>
            <v-icon v-else color="red">
              mdi-file-remove-outline
            </v-icon>
            {{ item.contracts }}
          </template>
          <template v-slot:[`item.downstreamRate`]="{ item }">
            <template
              v-if="
                item.isftth === true && item.ftthProductDescriptions.length > 1
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <label v-bind="attrs" v-on="on">
                    {{ item.ftthProductDescriptions[0] }}
                  </label>
                </template>
                <ul
                  v-for="(description, index) in item.ftthProductDescriptions"
                  :key="index"
                >
                  <li>{{ description }}</li>
                </ul>
              </v-tooltip>
            </template>
            <template v-else-if="item.isftth === true">
              <label>
                {{ item.ftthProductDescriptions[0] }}
              </label>
            </template>
          </template>
        </v-data-table>
      </div>
    </template>

    <!--District statistic-->
    <template
      v-if="
        ((city && city.city && district) ||
          (districtObject &&
            districtObject.zip &&
            districtObject.city &&
            districtObject.district)) &&
          cityDistrictTabActive &&
          rawResult &&
          rawResult.addresses &&
          rawResult.addresses.length > 0
      "
    >
      <div v-if="showDistrictFlag">
        <v-btn
          class="back-btn"
          color="primary"
          @click="returnToTotalOverview"
        >
          <v-icon>mdi-chart-box-plus-outline</v-icon>
          Zurück zur Gesamtüberischt
        </v-btn>
      </div>
      <v-row no-gutters>
        <v-col cols="auto">
          <DistrictCard :rawResult="rawResult" />
        </v-col>
        <v-col>
          <DetailMap :data="mergedAddresses" />
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="mergedAddresses"
        :item-class="row_classes"
        multi-sort
        class="elevation-1 my-6"
      >
        <template v-slot:[`item.houseno`]="{ item }">
          {{ item.houseno }}{{ item.houseno_suppl }}
        </template>
        <template v-slot:[`item.homes`]="{ item }">
          <v-icon>mdi-home</v-icon> {{ item.homes }}
        </template>
        <template v-slot:[`item.offices`]="{ item }">
          <v-icon>mdi-factory</v-icon> {{ item.offices }}
        </template>
        <template v-slot:[`item.contracts`]="{ item }">
          <v-icon v-if="item.isftth === true" color="green">
            mdi-file-check
          </v-icon>
          <v-icon v-else color="red">
            mdi-file-remove-outline
          </v-icon>
          {{ item.contracts }}
        </template>
        <template v-slot:[`item.downstreamRate`]="{ item }">
          <template
            v-if="
              item.isftth === true && item.ftthProductDescriptions.length > 1
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <label v-bind="attrs" v-on="on">
                  {{ item.ftthProductDescriptions[0] }}
                </label>
              </template>
              <ul
                v-for="(description, index) in item.ftthProductDescriptions"
                :key="index"
              >
                <li>{{ description }}</li>
              </ul>
            </v-tooltip>
          </template>
          <template v-else-if="item.isftth === true">
            <label>
              {{ item.ftthProductDescriptions[0] }}
            </label>
          </template>
        </template>
      </v-data-table>
      <div v-if="notFoundAddresses">
        <v-alert
          v-if="rawResult && rawResult.addresses && rawResult.addresses.length"
          dense
          type="error"
        >
          Einige Adressen konnten in der Address-Datenbank nicht gefunden
          werden!
        </v-alert>
        <v-data-table
          style="background: rgba(255, 0, 0, 0.05)"
          :headers="tableHeadersNotFound"
          :items="notFoundAddresses"
          :item-class="row_classes"
          multi-sort
          class="elevation-1 my-6"
        >
          <template v-slot:[`item.id`]>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="red"
                  v-on="on"
                >
                  mdi-home
                </v-icon>
              </template>
              <span>Annahme Hauseinheit</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.houseno`]="{ item }">
            {{ item.houseno }}{{ item.houseno_suppl }}
          </template>
          <template v-slot:[`item.homes`]="{ item }">
            <v-icon>mdi-home</v-icon> {{ item.homes }}
          </template>
          <template v-slot:[`item.offices`]="{ item }">
            <v-icon>mdi-factory</v-icon> {{ item.offices }}
          </template>
          <template v-slot:[`item.contracts`]="{ item }">
            <v-icon v-if="item.isftth === true" color="green">
              mdi-file-check
            </v-icon>
            <v-icon v-else color="red">
              mdi-file-remove-outline
            </v-icon>
            {{ item.contracts }}
          </template>
          <template v-slot:[`item.downstreamRate`]="{ item }">
            <template
              v-if="
                item.isftth === true && item.ftthProductDescriptions.length > 1
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <label v-bind="attrs" v-on="on">
                    {{ item.ftthProductDescriptions[0] }}
                  </label>
                </template>
                <ul
                  v-for="(description, index) in item.ftthProductDescriptions"
                  :key="index"
                >
                  <li>{{ description }}</li>
                </ul>
              </v-tooltip>
            </template>
            <template v-else-if="item.isftth === true">
              <label>
                {{ item.ftthProductDescriptions[0] }}
              </label>
            </template>
          </template>
        </v-data-table>
      </div>
    </template>

    <!--Project statistic-->
    <template
      v-if="
        projectTabActive &&
          projectItem &&
          projectItem.projektId &&
          rawResultProject &&
          rawResultProject.addresses &&
          rawResultProject.addresses.length > 0
      "
    >
      <v-row no-gutters>
        <v-col cols="auto">
          <DistrictCard :rawResult="rawResultProject" />
        </v-col>
        <v-col>
          <DetailMap
            :data="mergedAddresses"
            :projectId="projectItem.projektId"
          />
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="mergedAddresses"
        :item-class="row_classes"
        multi-sort
        class="elevation-1 my-6"
      >
        <template v-slot:[`item.houseno`]="{ item }">
          {{ item.houseno }}{{ item.houseno_suppl }}
        </template>
        <template v-slot:[`item.homes`]="{ item }">
          <v-icon>mdi-home</v-icon> {{ item.homes }}
        </template>
        <template v-slot:[`item.offices`]="{ item }">
          <v-icon>mdi-factory</v-icon> {{ item.offices }}
        </template>
        <template v-slot:[`item.contracts`]="{ item }">
          <v-icon v-if="item.isftth === true" color="green">
            mdi-file-check
          </v-icon>
          <v-icon v-else color="red">
            mdi-file-remove-outline
          </v-icon>
          {{ item.contracts }}
        </template>
        <template v-slot:[`item.downstreamRate`]="{ item }">
          <template
            v-if="
              item.isftth === true && item.ftthProductDescriptions.length > 1
            "
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <label v-bind="attrs" v-on="on">
                  {{ item.ftthProductDescriptions[0] }}
                </label>
              </template>
              <ul
                v-for="(description, index) in item.ftthProductDescriptions"
                :key="index"
              >
                <li>{{ description }}</li>
              </ul>
            </v-tooltip>
          </template>
          <template v-else-if="item.isftth === true">
            <label>
              {{ item.ftthProductDescriptions[0] }}
            </label>
          </template>
        </template>
      </v-data-table>
      <div v-if="notFoundAddresses">
        <v-alert
          v-if="
            rawResultProject &&
              rawResultProject.addresses &&
              rawResultProject.addresses.length
          "
          dense
          type="error"
        >
          Einige Adressen konnten in der Address-Datenbank nicht gefunden
          werden!
        </v-alert>
        <v-data-table
          style="background: rgba(255, 0, 0, 0.05)"
          :headers="tableHeadersNotFound"
          :items="notFoundAddresses"
          :item-class="row_classes"
          multi-sort
          class="elevation-1 my-6"
        >
          <template v-slot:[`item.id`]>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  color="red"
                  v-on="on"
                >
                  mdi-home
                </v-icon>
              </template>
              <span>Annahme Hauseinheit</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.houseno`]="{ item }">
            {{ item.houseno }}{{ item.houseno_suppl }}
          </template>
          <template v-slot:[`item.homes`]="{ item }">
            <v-icon>mdi-home</v-icon> {{ item.homes }}
          </template>
          <template v-slot:[`item.offices`]="{ item }">
            <v-icon>mdi-factory</v-icon> {{ item.offices }}
          </template>
          <template v-slot:[`item.contracts`]="{ item }">
            <v-icon v-if="item.isftth === true" color="green">
              mdi-file-check
            </v-icon>
            <v-icon v-else color="red">
              mdi-file-remove-outline
            </v-icon>
            {{ item.contracts }}
          </template>
          <template v-slot:[`item.downstreamRate`]="{ item }">
            <template
              v-if="
                item.isftth === true && item.ftthProductDescriptions.length > 1
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <label v-bind="attrs" v-on="on">
                    {{ item.ftthProductDescriptions[0] }}
                  </label>
                </template>
                <ul
                  v-for="(description, index) in item.ftthProductDescriptions"
                  :key="index"
                >
                  <li>{{ description }}</li>
                </ul>
              </v-tooltip>
            </template>
            <template v-else-if="item.isftth === true">
              <label>
                {{ item.ftthProductDescriptions[0] }}
              </label>
            </template>
          </template>
        </v-data-table>
      </div>
    </template>

    <!-- Project simple statistic -->
    <template
      v-if="
        projectSimpleTabActive &&
          projectSimpleItem &&
          projectSimpleItem.projektId &&
          rawResultProjectSimpel &&
          rawResultProjectSimpel.addresses &&
          rawResultProjectSimpel.addresses.length > 0
      "
    >
      <StatisticSimple
        :projectId="rawResultProjectSimpel.projectId"
        :statisticQuota="rawResultProjectSimpel.statisticQuota"
        :addresses="addressesProjectSimple"
        :notFoundAddressesSimpel="notFoundAddressesSimpel"
      />
    </template>

    <v-overlay v-if="loading">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      />
    </v-overlay>
    <v-overlay v-if="loadingExport">
      <v-progress-circular
        :size="120"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { HTTP } from '@/main/httpClient.js';
import ErrorMessageBuilder from '@/util/ErrorMessageBuilder.js';
import NotificationObject from '@/main/NotificationObject.js';
import StatisticSimple from '@/components/statistic/StatisticSimple.vue';

export default {
  name: 'ConstructionStatistic',
  components: {
    NotificationObject,
    StatisticSimple,
    DetailMap: () => import('@/components/constructionProjects/DetailMap.vue'),
    DistrictCard: () =>
      import('@/components/constructionProjects/DistrictCard.vue'),
    AddressDistrictSelection: () =>
      import('@/components/statistic/AddressDistrictSelection.vue'),
    ProjectNameSelection: () =>
      import('@/components/statistic/ProjectNameSelection.vue')
  },
  data: () => ({
    projectTabActive: false,
    projectSimpleTabActive: true,
    cityDistrictTabActive: false,
    projectsLoaded: false,
    projects: undefined,
    project: undefined,
    loadingExport: false,
    addresses: undefined,
    city: null,
    district: '',
    districtObject: undefined,
    timer: null,
    timerSeconds: 0,
    loading: false,
    isUpdating: false,
    rawResult: undefined,
    rawResultProjectSimpel: undefined,
    rawResultProject: undefined,
    lineView: false,
    notFoundAddresses: undefined,
    notFoundAddressesSimpel: undefined,
    showDistrictFlag: false,
    projectItem: undefined,
    projectSimpleItem: undefined,
    tableHeaders: [
      {
        text: 'ID',
        align: 'start',
        sortable: true,
        value: 'id',
        class: 'title-attr',
        width: '50px'
      },
      { text: 'PLZ', value: 'zip', width: '80px' },
      { text: 'Ort', value: 'city' },
      { text: 'Ortsteil', value: 'district' },
      { text: 'Straße', value: 'street' },
      { text: 'Hausnummer', value: 'houseno', width: '100px' },
      { text: 'Wohneinheiten', value: 'homes', width: '120px' },
      { text: 'Gewerbeeinheiten', value: 'offices', width: '130px' },
      { text: 'Vertrag vorhanden', value: 'contracts', width: '150px' },
      {
        text: 'Produkte',
        value: 'downstreamRate',
        width: '150px'
      }
    ],
    tableHeadersNotFound: [
      {
        text: '',
        align: 'start',
        sortable: true,
        value: 'id',
        class: 'title-attr',
        width: '50px'
      },
      { text: 'PLZ', value: 'zip', width: '80px' },
      { text: 'Ort', value: 'city' },
      { text: 'Ortsteil', value: 'district' },
      { text: 'Straße', value: 'street' },
      { text: 'Hausnummer', value: 'houseno', width: '100px' },
      { text: 'Wohneinheiten', value: 'homes', width: '120px' },
      { text: 'Gewerbeeinheiten', value: 'offices', width: '130px' },
      { text: 'Vertrag vorhanden', value: 'contracts', width: '150px' },
      { text: 'Produkte', value: 'downstreamRate', width: '150px' }
    ]
  }),
  computed: {
    mergedAddresses() {
      let merged = [];
      if (
        this.rawResult &&
        this.rawResult.addresses &&
        this.rawResult.addresses.length > 0
      ) {
        this.rawResult.addresses.forEach(function (item) {
          merged = [...merged, ...item];
        });
      } else if (
        this.rawResultProject &&
        this.rawResultProject.addresses &&
        this.rawResultProject.addresses.length > 0
      ) {
        this.rawResultProject.addresses.forEach(function (item) {
          merged = [...merged, ...item];
        });
      }
      return merged;
    },
    addressesProjectSimple() {
      if (
        this.rawResultProjectSimpel &&
        this.rawResultProjectSimpel.addresses &&
        this.rawResultProjectSimpel.addresses.length > 0
      ) {
        return this.rawResultProjectSimpel.addresses;
      } else {
        return [];
      }
    }
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    }
  },
  mounted() {
    this.getAllProjects();
  },
  methods: {
    showMessage: function (type, description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, description)
      );
    },
    row_classes(item) {
      if (item.match === 1) {
        return 'highlighted-row';
      }
    },
    getAllProjects() {
      HTTP.get('/statistics/allProjects').then((result) => {
        this.projects = result.data;
      });
      this.projectsLoaded = true;
    },
    exportExcel() {
      if (this.projectTabActive) {
        this.exportProjectStatistic();
      } else if (this.projectSimpleTabActive) {
        this.exportProjectStatisticSimple();
      } else {
        this.exportStatistic(this.city, this.district);
      }
    },
    exportProjectStatistic() {
      this.loadingExport = true;
      HTTP.get(
        `/statistics/exportProject?projectId=${this.projectItem.projektId}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportProjectStatisticSimple() {
      this.loadingExport = true;
      HTTP.get(
        `/statistics/exportProjectSimple?projectId=${this.projectSimpleItem.projektId}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Die Daten konnten nicht exportiert werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    exportStatistic: function (city, district) {
      this.loadingExport = true;
      let zip;
      let cityString;
      let districtString;
      if (this.districtObject && this.districtObject.length) {
        zip = this.districtObject.zip;
        cityString = this.districtObject.city;
        districtString = this.districtObject.district;
      } else {
        zip = this.city.zip;
        cityString = this.city.city;
        districtString = this.district ? this.district : '';
      }

      HTTP.get(
        `/statistics/export?zip=${zip}&city=${cityString}&district=${districtString}`,
        {
          responseType: 'blob'
        }
      )
        .then((resp) => {
          const responseHeaders = resp.headers;
          const mediaType = responseHeaders['content-type'];
          const fileName = responseHeaders['content-disposition'].split('=')[1];
          const blob = new Blob([resp.data], {
            type: mediaType
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Es konnten keine Adressen geladen werden'
            )
          )
        )
        .finally(() => {
          this.loadingExport = false;
        });
    },
    returnToTotalOverview() {
      this.showDistrictFlag = false;
      this.district = '';
      this.getConstructionQuota();
    },
    retrieveStatisticData() {
      if (this.projectTabActive) {
        this.getProjectQuota();
      } else if (this.projectSimpleTabActive) {
        this.getProjectSimpleQuota();
      } else {
        this.getConstructionQuota();
      }
    },
    updateStatisticCache(projectId) {
      HTTP.get(`/statistics/projectStatisticCache?projectId=${projectId}`);
    },
    getProjectQuota() {
      this.loading = true;
      const _this = this;
      const timerInterval = setInterval(function () {
        _this.timerSeconds = (parseFloat(_this.timerSeconds) + 0.1).toFixed(1);
      }, 100);
      HTTP.get(`/statistics/project?projectId=${this.projectItem.projektId}`)
        .then((result) => {
          this.notFoundAddresses = undefined;
          this.rawResultProject = result.data;
          if (
            this.rawResultProject.notFoundInGeoSys &&
            this.rawResultProject.notFoundInGeoSys.length
          ) {
            this.notFoundAddresses = this.rawResultProject.notFoundInGeoSys;
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Es konnten keine Adressen geladen werden'
            )
          )
        )
        .finally(() => {
          this.loading = false;
          clearInterval(timerInterval);
          this.timerSeconds = 0;
          this.updateStatisticCache(this.projectItem.projektId);
        });
    },
    getProjectSimpleQuota() {
      this.loading = true;
      const _this = this;
      const timerInterval = setInterval(function () {
        _this.timerSeconds = (parseFloat(_this.timerSeconds) + 0.1).toFixed(1);
      }, 100);
      HTTP.get(
        `/statistics/simplifiedProjectStatistics?projectId=${this.projectSimpleItem.projektId}`
      )
        .then((result) => {
          this.notFoundAddressesSimpel = undefined;
          this.rawResultProjectSimpel = result.data;
          if (
            this.rawResultProjectSimpel.notFoundInGeoSys &&
            this.rawResultProjectSimpel.notFoundInGeoSys.length
          ) {
            this.notFoundAddressesSimpel =
              this.rawResultProjectSimpel.notFoundInGeoSys;
          } else {
            this.notFoundAddressesSimpel = [];
          }
        })
        .catch((err) =>
          this.showMessage(
            'error',
            ErrorMessageBuilder.buildErrorMessage(
              err.response.data,
              'Es konnten keine Adressen geladen werden'
            )
          )
        )
        .finally(() => {
          this.loading = false;
          clearInterval(timerInterval);
          this.timerSeconds = 0;
          this.updateStatisticCache(this.projectSimpleItem.projektId);
        });
    },
    getConstructionQuota() {
      if (
        (this.city && this.city.city && this.city.zip) ||
        (this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district)
      ) {
        this.loading = true;
        let zip;
        let citystring;
        let districtString;
        if (
          this.districtObject &&
          this.districtObject.zip &&
          this.districtObject.city &&
          this.districtObject.district
        ) {
          zip = this.districtObject.zip;
          citystring = this.districtObject.city;
          districtString = this.districtObject.district;
        } else if (this.city && this.city.zip && this.city.city) {
          zip = this.city.zip;
          citystring = this.city.city;
          districtString = this.district ? this.district : '';
        }
        const _this = this;
        const timerInterval = setInterval(function () {
          _this.timerSeconds = (parseFloat(_this.timerSeconds) + 0.1).toFixed(
            1
          );
        }, 100);
        HTTP.get(
          `/statistics/statistics?zip=${zip}&city=${citystring}&district=${districtString}`
        )
          .then((result) => {
            this.notFoundAddresses = undefined;
            this.rawResult = result.data;
            if (
              this.rawResult.notFoundInGeoSys &&
              this.rawResult.notFoundInGeoSys.length
            ) {
              this.notFoundAddresses = this.rawResult.notFoundInGeoSys;
            }
          })
          .catch((err) =>
            this.showMessage(
              'error',
              ErrorMessageBuilder.buildErrorMessage(
                err.response.data,
                'Die Daten konnten nicht exportiert werden'
              )
            )
          )
          .finally(() => {
            this.loading = false;
            clearInterval(timerInterval);
            this.timerSeconds = 0;
          });
      }
    },
    showDistrict(district) {
      if (district) {
        this.showDistrictFlag = true;
        this.updateDistrict(district);
        this.getConstructionQuota();
      }
    },
    updateCity(city) {
      this.city = city;
      if (!this.city && !this.district) {
        this.rawResult = {};
      }
    },
    updateDistrict(district) {
      this.district = district;
      if (!district) {
        this.rawResult = {};
      }
    },
    updateDistrictObject(obj) {
      this.districtObject = obj;
    },
    updateProjektItem(projectItem) {
      if (this.projectTabActive) {
        this.projectItem = projectItem;
        if (!projectItem) {
          this.rawResult = {};
        }
      }
      if (this.projectSimpleTabActive) {
        this.projectSimpleItem = projectItem;
        if (!projectItem) {
          this.rawResultProjectSimpel = {};
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.v-data-table ::v-deep .highlighted-row {
  background: rgba(0, 255, 0, 0.025);
}
.v-tab {
  position: relative;
}
.v-tab ::v-deep #info-obsolet {
  position: absolute;
  top: 0px;
  right: 0px;
}
.export-btn {
  margin-left: 10px;
}
.back-btn {
  margin-bottom: 10px;
}
</style>

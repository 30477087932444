var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"margin-bottom",attrs:{"elevation":"1"}},[_c('v-container',[_c('v-autocomplete',{attrs:{"items":_vm.listOfGroups,"label":"LDAP Gruppe"},model:{value:(_vm.groupFilter),callback:function ($$v) {_vm.groupFilter=$$v},expression:"groupFilter"}})],1),_c('v-container',[_c('v-list',[_vm._l((_vm.routes),function(route,index){return [(
              !route.children &&
                !!route.meta &&
                route.meta.visible &&
                _vm.includesGroupFilter(route)
            )?_c('v-list-group',{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(route.icon)+" ")])],1),_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(route.name)+" ")])]},proxy:true}],null,true)},[_c('v-list-item',{key:index + 'sub',staticClass:"submenu",attrs:{"dense":""}},[_c('v-list',[(route.meta.auth)?_c('div',_vm._l((route.meta.auth),function(group){return _c('v-list-item',{key:group,staticClass:"submenu",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(group)+" ")])],1)}),1):_c('div',[_c('v-list-item',{staticClass:"submenu",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" Alle ")])],1)],1)])],1)],1):_vm._e(),(
              route.children &&
                !!route.meta &&
                route.meta.visible &&
                _vm.includesGroupFilter(route)
            )?_c('v-list-group',{key:index,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(route.icon)+" ")])],1),_c('v-list-item-title',{staticClass:"primary--text"},[_vm._v(" "+_vm._s(route.name)+" ")])]},proxy:true}],null,true)},[_vm._l((route.children),function(child,index2){return [(
                  child.meta &&
                    child.meta.visible &&
                    _vm.includesGroupFilter(child)
                )?_c('v-list-group',{key:index2,attrs:{"sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[(child.icon)?_c('v-icon',[_vm._v(" "+_vm._s(child.icon)+" ")]):_c('v-icon',[_vm._v(" mdi-chevron-right ")])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(child.name)+" ")])]},proxy:true}],null,true)},[_c('v-list-item',{key:index + 'sub',staticClass:"submenu",attrs:{"dense":""}},[_c('v-list',[(child.meta.auth)?_c('div',_vm._l((child.meta.auth),function(group){return _c('v-list-item',{key:group,staticClass:"submenu",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(group)+" ")])],1)}),1):_c('div',[_c('v-list-item',{staticClass:"submenu",attrs:{"dense":""}},[_c('v-list-item-title',[_vm._v(" Alle ")])],1)],1)])],1)],1):_vm._e()]})],2):_vm._e()]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
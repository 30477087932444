<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'SelocaWrapper',
  components: {},
  data: () => ({}),
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>
<style lang="scss" scoped></style>

<template>
  <div>
    <v-card elevation="1" class="margin-bottom">
      <v-card-title>
        Benutzer mit eingerichteter Zwei-Faktor-Authentifikation
        <v-spacer></v-spacer>
        <v-text-field
          v-model="userFilter"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          :loading="loading"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="[
          {
            text: 'Benutzer',
            value: 'userName',
            width: '80%'
          },
          {
            text: 'Aktionen',
            value: 'actions'
          }
        ]"
        :items="tableData"
        :search="userFilter"
        dense
        class="elevation-1 margin-top-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="button-wrapper">
            <v-btn
              small
              outlined
              color="var(--goe-fontColor-darkish)"
              @click="resetUser2FA(item.userName)"
            >
              2FA Zurücksetzen
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { HTTP } from '../main/httpClient';
import NotificationObject from '@/main/NotificationObject.js';
export default {
  name: 'TwoFactorAdmin',
  components: {},
  data: () => ({
    userFilter: '',
    users: [],
    loading: true
  }),
  computed: {
    tableData: function () {
      const tableData = [];
      this.users.forEach((user) => {
        const userTableItem = {
          userName: user,
          reset: ''
        };
        tableData.push(userTableItem);
      });
      return tableData;
    }
  },
  mounted: function () {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      this.loading = true;
      HTTP.get('/authentication/twofactor/usersSetUp')
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => console.log(error))
        .finally((this.loading = false));
    },
    resetUser2FA(user) {
      HTTP.post('/authentication/twofactor/resetForUser/' + user).then(() => {
        this.loadUsers();
        this.$store.commit(
          'addNotification',
          new NotificationObject(
            'success',
            `Die 2FA-Authentifizierung des Benutzers ${user} wurde erfolgreich zurückgesetzt.`
          )
        );
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 20px;
}
</style>

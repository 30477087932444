<template>
  <div>
    <MSANConfigurationStart />
  </div>
</template>

<script>
import MSANConfigurationStart from '@/processLaunch/msanConfig/MSANConfigStart.vue';

export default {
  name: 'MSANConfig',
  components: {
    MSANConfigurationStart
  },
  data: () => ({}),
  methods: {}
};
</script>
<style lang="scss" scoped></style>

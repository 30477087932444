<template>
  <div>
    <v-slide-group
      v-model="selectedTaskTypeFilters"
      :show-arrows="true"
      class="task-filter-bar"
      multiple
    >
      <v-slide-item>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="remove-filter-btn"
              v-bind="attrs"
              v-on="on"
            >
              <v-btn
                :disabled="selectedTaskTypeFilters.length <= 0"
                color="primary"
                depressed
                rounded
                small
                @click="selectedTaskTypeFilters = []"
                v-on="on"
              >
                <v-icon> mdi-filter-off</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Alle Filter entfernen</span>
        </v-tooltip>
      </v-slide-item>
      <v-slide-item
        v-for="taskType in allTaskTypes"
        :key="taskType.taskName"
        v-slot="{ active, toggle }"
        :value="taskType.taskName"
      >
        <v-btn
          :color="active ? 'primary' : ''"
          :disabled="isLoading"
          :input-value="active"
          class="task-filter"
          depressed
          rounded
          small
          @click="toggle"
        >
          {{ taskType.taskName }} ({{ taskType.count }})
        </v-btn>
      </v-slide-item>
    </v-slide-group>

    <v-row>
      <v-col
        :class="{ 'light-shadow': contentId === 'taskDetails' }"
        :md="
          contentId === 'taskDetails' || contentId === 'createGenericTask'
            ? 3
            : 12
        "
        class="tasklist"
      >
        <TaskList
          :count="itemTotalCount"
          :isLoading="isLoading"
          :items="items"
          :selectedId="selectedItem !== null ? selectedItem.id : null"
          :selectedTaskTypeFilters="selectedTaskTypeFilters"
          @onCreateTask="contentId = 'createGenericTask'"
          @onPaginationPageChanged="onPaginationPageChanged"
          @onRefresh="getTasks"
          @onSearchTextChanged="onSearchTextChanged($event)"
          @onSelect="onSelectTask"
          @onSelectedTaskTypeFiltersChange="onSelectedTaskTypeFiltersChange"
          @onSortTypeChanged="onSortTypeChanged($event)"
        />
      </v-col>
      <v-col
        v-if="contentId === 'taskDetails' && contentId != 'createGenericTask'"
      >
        <TaskContent
          :data="selectedItem"
          @onClose="selectEntry(null)"
          @onUpdateEntry="updateSingleEntry"
          @refresh="refreshTasks"
        />
      </v-col>
      <v-col v-else-if="contentId === 'createGenericTask'">
        <CreateTask @onClose="contentId = null" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TaskList from '@/task/TaskList.vue';
import TaskContent from '@/task/TaskContent.vue';
import CreateTask from '@/task/CreateTask.vue';
import { HTTP } from '@/main/httpClient.js';
import Logger from '@/main/Logger.js';
import NotificationObject from '@/main/NotificationObject.js';
import { objectToQueryParamString } from '@/util/ObjectUtil.js';

const LOGGER_KEY = 'TASKS';

export default {
  name: 'Tasks',
  components: {
    TaskList,
    TaskContent,
    CreateTask,
    NotificationObject
  },
  data: () => ({
    items: [],
    itemTotalCount: 0,
    selectedItem: null,
    isLoading: false,
    contentId: undefined,
    lastBpmnSrc: null,
    selectedPageOptions: null,
    searchText: null,
    sortIndex: 0,
    allTaskTypes: [],
    selectedTaskTypeFilters: []
  }),
  mounted() {
    document.title = `Aufgabenliste  | ${process.env.VUE_APP_TITLE ?? 'Goetel SPOT'}`;
  },
  created: function () {
    this.getTasks();

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'triggerTasksRefresh') {
        this.getTasks();
      }
    });
  },
  methods: {
    onSortTypeChanged: function (event) {
      this.sortIndex = event;
      this.getTasks();
    },
    onSearchTextChanged: function (event) {
      this.searchText = event;
      this.getTasks();
    },
    onPaginationPageChanged: function (paginationInfo) {
      this.selectedPageOptions = paginationInfo;
      this.getTasks();
    },
    onSelectedTaskTypeFiltersChange() {
      this.getTasks();
    },
    showError: function (description) {
      this.$store.commit(
        'addNotification',
        new NotificationObject('error', description)
      );
    },
    selectEntry: function (entry) {
      Logger.log(
        LOGGER_KEY,
        'get new selection',
        entry === null ? null : entry.id
      );
      if (entry === null) {
        this.selectedItem = undefined;
      }
      this.selectedItem = entry;
      this.contentId = entry != null ? 'taskDetails' : 'dashboard';
      window.scrollTo(0, 0);
    },
    refreshTasks() {
      this.getTasks('selected');
    },
    onSelectTask: function (entry) {
      if (entry != null) {
        Logger.log(
          LOGGER_KEY,
          'on select task',
          entry === null ? null : entry.id
        );

        HTTP.get(`/engine/default/task/${entry.id}`)
          .then((resp) => this.selectEntry(resp.data))
          .catch(() =>
            this.showError('Die Prozessdetails konnten nicht geladen werden!')
          );
      }
    },
    getQueryParameters() {
      const target = {};
      try {
        const { page, perPage } = this.selectedPageOptions;
        Object.assign(target, { page: page, perPage: perPage });
      } catch (error) {
        console.log('selectedPageOptions null');
      }
      Object.assign(target, {
        search: this.searchText,
        sortType: this.sortIndex
      });

      const queryString = objectToQueryParamString(target);
      let queryParameter = '';
      if (queryString && queryString.trim().length > 0) {
        queryParameter = `?${queryString}`;
      }
      return queryParameter;
    },
    getAllTasksOfUser() {
      HTTP.get('/tasks/retrieveAllTasksOfUser')
        .then((resp) => {
          this.allTaskTypes = resp.data;
        })
        .catch(() =>
          this.showError('Die Aufträge konnten nicht abgeholt werden!')
        )
        .finally();
    },
    getTasks: function (val) {
      this.isLoading = true;
      // unselect the selected entry
      if (val !== 'selected') {
        this.selectEntry(null);
      }
      const queryParameter = this.getQueryParameters();
      this.getAllTasksOfUser();
      HTTP.post(
        `/tasks/customerInfo${queryParameter}`,
        this.selectedTaskTypeFilters
      )
        .then((resp) => {
          const items = resp.data.tasks.sort(
            (itemA, itemB) =>
              new Date(itemB.camundaTaskDto.created) -
              new Date(itemA.camundaTaskDto.created)
          );
          this.items = items;
          this.itemTotalCount = resp.data.totalTaskCount;
        })
        .catch(() =>
          this.showError('Die Aufträge konnten nicht abgeholt werden!')
        )
        .finally(() => (this.isLoading = false));
    },
    updateSingleEntry: function (entry) {
      Logger.log(LOGGER_KEY, 'get update for entry ', entry.id);

      const data = this.items.map((item) => {
        if (item.camundaTaskDto.id === entry.id) {
          item.camundaTaskDto = entry;
        }
        return item;
      });

      // Reselect currenty item => for show changes
      this.selectEntry(entry);
      this.items = data;
    }
  }
};
</script>
<style lang="scss" scoped>
.task-filter {
  font-size: 8pt;
}

.task-filter-bar {
  margin-bottom: 12px;
}

.remove-filter-btn {
  display: inline-flex;
}

.tasklist {
  transition: all 0.1s ease-in-out;
}

@media (max-width: 1400px) {
  .tasklist {
    font-size: 18px;
    max-width: 35%;
  }
}

@media (min-width: 1400px) {
  .tasklist {
    min-width: 380px;
  }
}
</style>

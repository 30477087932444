<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Seloca Artikelliste </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <ArtikelUbersichtTable />
  </div>
</template>

<script>
import ArtikelUbersichtTable from '@/components/seloca/ArtikelUbersichtTable.vue';

export default {
  name: 'SelocaArticleList',
  components: {
    ArtikelUbersichtTable
  },
  data: () => ({}),
  methods: {}
};
</script>
<style></style>

<template>
  <div>
    <!-- <ProcessLaunchWrapper
      lastBpmnSrc="Kundenanschaltung_Eingang_Auftragseingang.bpmn"
      title="Auftragserfassung"
    >-->
      <IncomingServiceOrder @onClose="abort = true" />
    <!-- </ProcessLaunchWrapper>-->
  </div>
</template>

<script>
import ProcessLaunchWrapper from '@/processLaunch/ProcessLaunchWrapper.vue';
import IncomingServiceOrder from '@/processLaunch/orderEntry/OrderProcessStart.vue';

export default {
  name: 'ServiceOrder',
  components: {
    ProcessLaunchWrapper,
    IncomingServiceOrder
  },
  data: () => ({
    abort: false
  }),
  watch: {
    abort() {
      this.$router.go(0);
    }
  },
  methods: {}
};
</script>
<style lang="scss" scoped></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Statistik ")]),_c('v-tabs',{attrs:{"background-color":"rgba(0,0,0,0.05)"}},[_c('v-tab',{attrs:{"disabled":""},on:{"change":function () {
            _vm.rawResultProject = {};
            _vm.rawResultProjectSimpel = {};
            _vm.cityDistrictTabActive = true;
            _vm.projectTabActive = false;
            _vm.projectSimpleTabActive = false;
          }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"info-obsolet","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-variant ")])]}}])},[_c('ul',[_c('li',[_vm._v("Ort / Ortsteile (obsolet)")]),_c('ul',[_c('li',[_vm._v("Anzeige eines oder mehrer Ortsteile im Ort")]),_c('li',[_vm._v(" Ist abhängig von der Schreibweise der Adresse im VarioCRM System ")]),_c('li',[_vm._v(" Adressen die in der Adressdatenbank Unbekannte sind werden angezeigt ")])])])]),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-home-city-outline ")]),_vm._v(" Ort / Ortsteil ")],1),_c('v-tab',{attrs:{"disabled":""},on:{"change":function () {
            _vm.rawResult = {};
            _vm.rawResultProjectSimpel = {};
            _vm.cityDistrictTabActive = false;
            _vm.projectTabActive = true;
            _vm.projectSimpleTabActive = false;
          }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"info-obsolet","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-variant ")])]}}])},[_c('ul',[_c('li',[_vm._v("Projekt (obsolet)")]),_c('ul',[_c('li',[_vm._v(" Hier werden alle Verträge ausgewertet die zu der Liste der Projektortsteile gehören ")]),_c('li',[_vm._v(" Ist abhängig von der Schreibweise der Adresse im VarioCRM System ")]),_c('li',[_vm._v(" Adressen die in der Adressdatenbank Unbekannte sind werden angezeigt ")])])])]),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-vector-polygon ")]),_vm._v(" Projekt ")],1),_c('v-tab',{on:{"change":function () {
            _vm.rawResult = {};
            _vm.rawResultProject = {};
            _vm.cityDistrictTabActive = false;
            _vm.projectTabActive = false;
            _vm.projectSimpleTabActive = true;
          }}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"info-obsolet","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-variant ")])]}}])},[_c('ul',[_c('li',[_vm._v("Projekt (Vertragszuordnung)")]),_c('ul',[_c('li',[_vm._v(" Hier werden alle Verträge ausgewertet die bisher mit einem Projekt in Verbindung genommen wurden ")]),_c('li',[_vm._v(" Wenn Aufträge nicht in dieser Liste Auftreten müssen sie noch nachgebessert werden. ")]),_c('li',[_vm._v("Dient zur Grundlage der in Monday reporteten Zahlen")]),_c('li',[_vm._v(" Ist abhängig von Adressdatenbank in PAST und den Projektpolygonen ")])])])]),_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-vector-polygon ")]),_vm._v(" Projekt (Vertragszuordnung) ")],1),_c('v-tab-item',[_c('v-card-text',[_c('AddressDistrictSelection',{attrs:{"city":_vm.city,"district":_vm.district},on:{"updateCity":_vm.updateCity,"updateDistrict":_vm.updateDistrict,"updateDistrictObject":_vm.updateDistrictObject}}),_c('br')],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('ProjectNameSelection',{attrs:{"projectItem":_vm.projectItem},on:{"updateProjektItem":_vm.updateProjektItem}})],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('ProjectNameSelection',{attrs:{"projectItem":_vm.projectSimpleItem},on:{"updateProjektItem":_vm.updateProjektItem}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.retrieveStatisticData}},[_c('v-icon',[_vm._v("mdi-chart-box-plus-outline")]),_vm._v(" Ausbauquote berechnen ")],1),(_vm.cityDistrictTabActive || _vm.projectTabActive)?_c('v-btn',{staticClass:"export-btn",attrs:{"color":"primary","disabled":(!_vm.mergedAddresses || _vm.mergedAddresses.length === 0) &&
            (!_vm.notFoundAddresses || _vm.notFoundAddresses.length === 0)},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" Export ")]):_vm._e(),(_vm.projectSimpleTabActive)?_c('v-btn',{staticClass:"export-btn",attrs:{"color":"primary","disabled":(!_vm.addressesProjectSimple || _vm.addressesProjectSimple.length === 0) &&
            (!_vm.notFoundAddressesSimpel || _vm.notFoundAddressesSimpel.length === 0)},on:{"click":function($event){return _vm.exportExcel()}}},[_vm._v(" Export ")]):_vm._e()],1)],1),_c('br'),(
      _vm.cityDistrictTabActive &&
        _vm.rawResult &&
        _vm.rawResult.districts &&
        _vm.rawResult.districts.length &&
        _vm.city
    )?[_c('DistrictCard',{key:-1,attrs:{"city":_vm.rawResult.city,"rawResult":_vm.rawResult}}),_c('br'),_vm._v("  "),_c('v-row',[_c('v-col',{attrs:{"md":"9"}},[_c('h4',{staticStyle:{"color":"#888","margin":"16px 0"}},[_vm._v(" Ortsteile: ")])]),_c('v-col',{staticClass:"text-right",attrs:{"md":"3"}},[_c('v-switch',{attrs:{"label":"Alternative Darstellung"},model:{value:(_vm.lineView),callback:function ($$v) {_vm.lineView=$$v},expression:"lineView"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"md":"12"}},[[_vm._l((_vm.rawResult.districts),function(item,index){return [_c('DistrictCard',{key:index,attrs:{"rawResult":item,"list-view":true,"line-view":_vm.lineView,"city":""},on:{"showDistrict":_vm.showDistrict}})]})]],2)],1),(_vm.notFoundAddresses)?_c('div',[(_vm.rawResult && _vm.rawResult.districts && _vm.rawResult.districts.length)?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" Einige Adressen konnten in der Address-Datenbank nicht gefunden werden! ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-6",staticStyle:{"background":"rgba(255, 0, 0, 0.05)"},attrs:{"headers":_vm.tableHeadersNotFound,"items":_vm.notFoundAddresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}],null,false,2263311445)},[_c('span',[_vm._v("Annahme Hauseinheit")])])]},proxy:true},{key:"item.houseno",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.houseno_suppl)+" ")]}},{key:"item.homes",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
    var item = ref.item;
return [(item.isftth === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
    var item = ref.item;
return [(
              item.isftth === true && item.ftthProductDescriptions.length > 1
            )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.isftth === true)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)})],1):_vm._e()]:_vm._e(),(
      ((_vm.city && _vm.city.city && _vm.district) ||
        (_vm.districtObject &&
          _vm.districtObject.zip &&
          _vm.districtObject.city &&
          _vm.districtObject.district)) &&
        _vm.cityDistrictTabActive &&
        _vm.rawResult &&
        _vm.rawResult.addresses &&
        _vm.rawResult.addresses.length > 0
    )?[(_vm.showDistrictFlag)?_c('div',[_c('v-btn',{staticClass:"back-btn",attrs:{"color":"primary"},on:{"click":_vm.returnToTotalOverview}},[_c('v-icon',[_vm._v("mdi-chart-box-plus-outline")]),_vm._v(" Zurück zur Gesamtüberischt ")],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('DistrictCard',{attrs:{"rawResult":_vm.rawResult}})],1),_c('v-col',[_c('DetailMap',{attrs:{"data":_vm.mergedAddresses}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 my-6",attrs:{"headers":_vm.tableHeaders,"items":_vm.mergedAddresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.houseno",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.houseno_suppl)+" ")]}},{key:"item.homes",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
    var item = ref.item;
return [(item.isftth === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
    var item = ref.item;
return [(
            item.isftth === true && item.ftthProductDescriptions.length > 1
          )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.isftth === true)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)}),(_vm.notFoundAddresses)?_c('div',[(_vm.rawResult && _vm.rawResult.addresses && _vm.rawResult.addresses.length)?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" Einige Adressen konnten in der Address-Datenbank nicht gefunden werden! ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-6",staticStyle:{"background":"rgba(255, 0, 0, 0.05)"},attrs:{"headers":_vm.tableHeadersNotFound,"items":_vm.notFoundAddresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}],null,false,2263311445)},[_c('span',[_vm._v("Annahme Hauseinheit")])])]},proxy:true},{key:"item.houseno",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.houseno_suppl)+" ")]}},{key:"item.homes",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
          var item = ref.item;
return [(item.isftth === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
          var item = ref.item;
return [(
              item.isftth === true && item.ftthProductDescriptions.length > 1
            )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.isftth === true)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)})],1):_vm._e()]:_vm._e(),(
      _vm.projectTabActive &&
        _vm.projectItem &&
        _vm.projectItem.projektId &&
        _vm.rawResultProject &&
        _vm.rawResultProject.addresses &&
        _vm.rawResultProject.addresses.length > 0
    )?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('DistrictCard',{attrs:{"rawResult":_vm.rawResultProject}})],1),_c('v-col',[_c('DetailMap',{attrs:{"data":_vm.mergedAddresses,"projectId":_vm.projectItem.projektId}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 my-6",attrs:{"headers":_vm.tableHeaders,"items":_vm.mergedAddresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.houseno",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.houseno_suppl)+" ")]}},{key:"item.homes",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
    var item = ref.item;
return [(item.isftth === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
    var item = ref.item;
return [(
            item.isftth === true && item.ftthProductDescriptions.length > 1
          )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.isftth === true)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)}),(_vm.notFoundAddresses)?_c('div',[(
          _vm.rawResultProject &&
            _vm.rawResultProject.addresses &&
            _vm.rawResultProject.addresses.length
        )?_c('v-alert',{attrs:{"dense":"","type":"error"}},[_vm._v(" Einige Adressen konnten in der Address-Datenbank nicht gefunden werden! ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 my-6",staticStyle:{"background":"rgba(255, 0, 0, 0.05)"},attrs:{"headers":_vm.tableHeadersNotFound,"items":_vm.notFoundAddresses,"item-class":_vm.row_classes,"multi-sort":""},scopedSlots:_vm._u([{key:"item.id",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-home ")])]}}],null,false,2263311445)},[_c('span',[_vm._v("Annahme Hauseinheit")])])]},proxy:true},{key:"item.houseno",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.houseno)+_vm._s(item.houseno_suppl)+" ")]}},{key:"item.homes",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-home")]),_vm._v(" "+_vm._s(item.homes)+" ")]}},{key:"item.offices",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-factory")]),_vm._v(" "+_vm._s(item.offices)+" ")]}},{key:"item.contracts",fn:function(ref){
        var item = ref.item;
return [(item.isftth === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-file-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-file-remove-outline ")]),_vm._v(" "+_vm._s(item.contracts)+" ")]}},{key:"item.downstreamRate",fn:function(ref){
        var item = ref.item;
return [(
              item.isftth === true && item.ftthProductDescriptions.length > 1
            )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('label',_vm._g(_vm._b({},'label',attrs,false),on),[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]}}],null,true)},_vm._l((item.ftthProductDescriptions),function(description,index){return _c('ul',{key:index},[_c('li',[_vm._v(_vm._s(description))])])}),0)]:(item.isftth === true)?[_c('label',[_vm._v(" "+_vm._s(item.ftthProductDescriptions[0])+" ")])]:_vm._e()]}}],null,true)})],1):_vm._e()]:_vm._e(),(
      _vm.projectSimpleTabActive &&
        _vm.projectSimpleItem &&
        _vm.projectSimpleItem.projektId &&
        _vm.rawResultProjectSimpel &&
        _vm.rawResultProjectSimpel.addresses &&
        _vm.rawResultProjectSimpel.addresses.length > 0
    )?[_c('StatisticSimple',{attrs:{"projectId":_vm.rawResultProjectSimpel.projectId,"statisticQuota":_vm.rawResultProjectSimpel.statisticQuota,"addresses":_vm.addressesProjectSimple,"notFoundAddressesSimpel":_vm.notFoundAddressesSimpel}})]:_vm._e(),(_vm.loading)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e(),(_vm.loadingExport)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"size":120,"color":"primary","indeterminate":""}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
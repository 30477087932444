<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Seloca Versandhistorie</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <OrderHistory />
  </div>
</template>

<script>
import OrderHistory from '@/components/seloca/OrderHistory.vue';

export default {
  name: 'SelocaOrderHistory',
  components: {
    OrderHistory
  },
  data: () => ({}),
  methods: {}
};
</script>
<style></style>

<template>
  <div>
    <v-card
      elevation="1"
      class="margin-bottom"
    >
      <v-container>
        <v-autocomplete
          v-model="groupFilter"
          :items="listOfGroups"
          label="LDAP Gruppe"
        ></v-autocomplete>
      </v-container>
      <v-container>
        <v-list>
          <template v-for="(route, index) in routes">
            <v-list-group
              v-if="
                !route.children &&
                  !!route.meta &&
                  route.meta.visible &&
                  includesGroupFilter(route)
              "
              :key="index"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="primary">
                    {{ route.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text">
                  {{ route.name }}
                </v-list-item-title>
              </template>
              <v-list-item
                :key="index + 'sub'"
                class="submenu"
                dense
              >
                <!-- list of all groups that are allowed here -->
                <v-list>
                  <div v-if="route.meta.auth">
                    <v-list-item
                      v-for="group in route.meta.auth"
                      :key="group"
                      class="submenu"
                      dense
                    >
                      <v-list-item-title>
                        {{ group }}
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                  <div v-else>
                    <v-list-item
                      class="submenu"
                      dense
                    >
                      <v-list-item-title> Alle </v-list-item-title>
                    </v-list-item>
                  </div>
                </v-list>
              </v-list-item>
            </v-list-group>
            <!-- childmenu -->
            <v-list-group
              v-if="
                route.children &&
                  !!route.meta &&
                  route.meta.visible &&
                  includesGroupFilter(route)
              "
              :key="index"
            >
              <template v-slot:activator>
                <v-list-item-icon>
                  <v-icon color="primary">
                    {{ route.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="primary--text">
                  {{ route.name }}
                </v-list-item-title>
              </template>
              <template v-for="(child, index2) in route.children">
                <v-list-group
                  v-if="
                    child.meta &&
                      child.meta.visible &&
                      includesGroupFilter(child)
                  "
                  :key="index2"
                  sub-group
                >
                  <template v-slot:activator>
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">
                        {{ child.icon }}
                      </v-icon>
                      <v-icon v-else>
                        mdi-chevron-right
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                      {{ child.name }}
                    </v-list-item-title>
                  </template>
                  <v-list-item
                    :key="index + 'sub'"
                    class="submenu"
                    dense
                  >
                    <!-- list of all groups that are allowed here -->
                    <v-list>
                      <div v-if="child.meta.auth">
                        <v-list-item
                          v-for="group in child.meta.auth"
                          :key="group"
                          class="submenu"
                          dense
                        >
                          <v-list-item-title>
                            {{ group }}
                          </v-list-item-title>
                        </v-list-item>
                      </div>
                      <div v-else>
                        <v-list-item
                          class="submenu"
                          dense
                        >
                          <v-list-item-title> Alle </v-list-item-title>
                        </v-list-item>
                      </div>
                    </v-list>
                  </v-list-item>
                </v-list-group>
              </template>
            </v-list-group>
            <!-- childmenu-end -->
          </template>
        </v-list>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Tools from '@/main/tools.js';

export default {
  name: 'PermissionOverview',
  components: {},
  data: () => ({
    groupFilter: 'Alle'
  }),
  computed: {
    routes: function () {
      const routes = this.$router.options.routes;

      for (const tool of Tools) {
        tool.name = `Tool :: ${tool.name}`;
        tool.meta = {
          visible: true,
          auth: tool.auth
        };
        routes.push(tool);
      }

      return routes;
    },
    listOfGroups: function () {
      let groups = ['Alle'];
      this.$router.options.routes.forEach((route) => {
        if (route.meta && route.meta.auth) {
          groups = groups.concat(route.meta.auth);
        }
        if (route.children) {
          route.children.forEach((child) => {
            if (child.meta && child.meta.auth) {
              groups = groups.concat(child.meta.auth);
            }
          });
        }
      });
      return [...new Set(groups)].sort();
    }
  },
  methods: {
    includesGroupFilter(route) {
      if (
        !route.meta.auth ||
        route.meta.auth.includes(this.groupFilter) ||
        this.groupFilter === 'Alle'
      ) {
        return true;
      }
      if (route.children) {
        let includedInChildren = false;
        route.children.forEach((child) => {
          includedInChildren =
            includedInChildren || this.includesGroupFilter(child);
        });
        return includedInChildren;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.margin-bottom {
  margin-bottom: 20px;
}
</style>
